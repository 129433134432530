import React, { useState, useEffect } from "react";

import logo from "./sp_logo_left.jpg";
import "./App.css";
import { Container } from 'react-bootstrap'
import * as XLSX from 'xlsx';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { DataGrid } from '@mui/x-data-grid';
import processorSample from './processor_samplefile.csv';
import resultSample from './result_samplefile.xlsx';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation,
  useParams
} from 'react-router-dom'

const axios = require('axios')
const csv = require('csvtojson'),

csvToJson = async (path) => {
  try {
    const jsonArray = await csv().fromString(path);
    return jsonArray;
  } catch (err) {
    throw err;
  }
};

function App() {
  var homeLink = ""
  if (window.location.href.indexOf("upload") !== -1) {
    homeLink = window.location.href;
  } else {
    homeLink = window.location.href.split("/")[0] + "//" + window.location.href.split("/")[2] + "/upload/" + window.location.href.split("/")[3];
  }
    return <Router>
    <Container>
      <div className={'my-5'}>
        <a href={homeLink}>
        <img src={logo} className={'d-block m-auto '} style={{ width: '50%', maxWidth: '20rem' }} alt="logo"/>
        </a>
      </div>
      <Switch>
        <Route exact path="/">
          <Redirect/>
        </Route>
        <Route exact path="/upload/:id">
          <Upload/>
        </Route>
        <Route exact path="/:id/confirm">
          <ConfirmPage/>
        </Route>
        <Route exact path="/:id/completed">
          <CompletedPage/>
        </Route>
        <Route exact path="/:id/error">
          <ErrorPage/>
        </Route>
      </Switch>
    </Container>
  </Router>
}

const Redirect = () => {
  window.location.assign("upload/");
  return <>
  </>
}

const CompletedPage = () => {
  const { id } = useParams()
  const location = useLocation();
  var data = (location.state);
  if (data === undefined) {
      window.location.assign("upload/" + id);
    return <>
    </>
  } else {
    return <div className="card my-4 border-primary">
      <div className="card-header text-white bg-primary">
        <strong>UPLOADS SENT</strong>
      </div>
      <div>
        <p>Data has been sent to the server and is being processed. You will receive an email update (to - {data.field_2_raw.email}) upon completion of the same.</p>
      </div>
    </div>
  }
}

/* eslint-disable no-return-assign, no-param-reassign */
const ErrorPage = () => {
  const { id } = useParams()
  const location = useLocation();
  var data = (location.state);
  
  if (data === undefined) {
    window.location.assign("upload/" + id);
    return <>
    </>
  } else {
    return <div className="card my-4 border-primary">
      <div className="card-header text-white bg-primary">
        <strong>AN ERROR OCCURED</strong>
      </div>
      <div>
        <p>{data.message}</p>
        <div hidden={data.link === ""}>
          <button className={'btn btn-primary btn-lg d-block w-100 mt-3'}>
            {data.link==="Processor" && 
              <a href={processorSample} download={data.link+"_samplefile.xlsx"} style={{color: "#ffffff", textAlign: "center"}}>Download Sample</a>
            }
            {data.link==="Result" && 
              <a href={resultSample} download={data.link+"_samplefile.xlsx"} style={{color: "#ffffff", textAlign: "center"}}>Download Sample</a>
            }
            </button>
        </div>
      </div>
    </div>
  }
}
/* eslint-enable no-return-assign, no-param-reassign */

const Upload = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const { push } = useHistory();
  const [fileval, setFiles] = useState('')
  const [processorfile, setProcessorFile] = useState('')
  const [resultfile, setResultFile] = useState('')
  const [showProcessor, setshowProcessor] = useState(false)
  const [showResult, setshowResult] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [message, setmessage] = useState("Loading...")

  useEffect(() => {
    axios.get("https://api.knack.com/v1/objects/object_1/records/" + id, {
        headers: {
          "X-Knack-REST-API-Key": process.env.REACT_APP_KNACKAPI_KEY,
          "X-Knack-Application-Id": process.env.REACT_APP_KNACKAPP_ID,
          "Cache-Control": `no-cache`
        }
      }).then(data => {
      if(data.data.profile_keys !== undefined) {
        if(data.data.profile_keys.includes("labTechnicianRole")){
          setUser(data.data)
        }
      } else {
        setmessage("Access Denied!")
      }
    })
  }, [id])

  useEffect(() => {
    (async () => {
      if (fileval === "file1") {
        setshowProcessor(true);
        setshowResult(false);
      } else if (fileval === "file2") {
        setshowProcessor(false);
        setshowResult(true);
      } else if (fileval === "file3") {
        setshowProcessor(true);
        setshowResult(true);
      } else if (fileval === "") {
        setshowProcessor(false);
        setshowResult(false);
      }
    })()
  }, [fileval])
  if (!user) return <div>{message}</div>


  const handleFileChange = (e) => {
    const id = e.target.id;
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      (async () => {
        if (id === "processorfile") {
          setProcessorFile(await csvToJson(data))
        } else if (id === "resultfile") {
          setResultFile(await csvToJson(data));
        }
      })()
    };
    reader.readAsBinaryString(file);
  }

  async function sendFiles (processor, result, user) {
    setIsLoading(true)
    var data = {}
    data["user"] = user
    if (processor !== "") {
      data["processor"] = processor
      let keys = Object.keys(processor[0]);
      if ((!(keys.includes("Sample ID") && keys.includes("Processor") && keys.includes("Ext") && keys.includes("CheckIn Date"))) && (!(keys.includes("Sample ID") && keys.includes("Processor") && keys.includes("Ext") && keys.includes("CheckIn Date") && keys.includes("DNA Wash Lot #") && keys.includes("DNA Wash Date Opened") && keys.includes("Lysis/binding Lot #") && keys.includes("Lysis/binding Date Opened") && keys.includes("Beads Lot #") && keys.includes("Beads Date Made") && keys.includes("PK Lot #") && keys.includes("PK Date Made") && keys.includes("MasterMix maker initials")))) {
        push({
          pathname: "/" + user.id + '/error',
          state: {
            error: "Processor File Format Incorrect",
            message: "The processor file format is incorrect. Please ensure the right format including header names are properly included. You can download a sample below",
            link: "Processor"
          }
        })
        return false;
      }
    }
    if (result !== "") {
      data["result"] = result
      let keys = Object.keys(result[0]);
      if (!(keys.includes("SampleID") && keys.includes("yvalue") && keys.includes("totalvalue") && keys.includes("gender") && keys.includes("checkindate"))) {
        push({
          pathname: "/" + user.id + '/error',
          state: {
            error: "Result File Format Incorrect",
            message: "The result file format is incorrect. Please ensure the right format including header names are properly included. You can download a sample below",
            link: "Result"
          }
        })
        return false;
      }
    }

    var config = {
      method: "POST",
      url: process.env.REACT_APP_API_URL + '/uploadv2/parsecsv/',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config).then(res => {
      console.log(`Success`);
      push({ 
        pathname: "/" + user.id + '/confirm',
        state: res.data
      });
    })
    .catch(err => {
      console.log(err);
    })
  }

 
  return <div>
    <div className="card my-4 border-primary">
    <div className="card-header text-white bg-primary">
      <strong>Upload Files</strong>
    </div>
    <div className="card-body">
      <div className="form-group my-3">
        <div className="form-group my-3">
          <label htmlFor="fileupload">Files to upload:&nbsp;</label>
          <select className={'custom-select'} value={fileval}
          onChange={e => setFiles(e.target.value)}>
            <option value={''} key={''}>Select</option>
            <option value={'file1'} key={'file1'}>Instrument File</option>
            <option value={'file2'} key={'file2'}>Result File</option>
            <option value={'file3'} key={'file3'}>Both Instrument &amp; Result Files</option>
          </select>
        </div>
        <div style={{display:  showProcessor ? 'block' : 'none' }} id="processorsection" className="form-group my-3">
          <label htmlFor="processorfile">Processor File:&nbsp;</label>
          <input id="processorfile" type="file" accept=".csv" className={'custom-select'}
            onChange={e => handleFileChange(e)} />
        </div>
        <div style={{display: showResult ? 'block' : 'none'}} id="resultsection" className="form-group my-3">
          <label htmlFor="resultfile">Result File:&nbsp;</label>
          <input id="resultfile" type="file" accept=".csv, .xlsx" className={'custom-select'}
            onChange={e => handleFileChange(e)} />
        </div>
        <button className={'btn btn-primary btn-lg d-block w-100 mt-3'}
          disabled={(!showProcessor && !showResult) || (showProcessor && processorfile === "") || (showResult && resultfile === "") || isLoading} onClick={() => sendFiles(processorfile, resultfile, user)}>
            {(isLoading &&
        <span className="spinner-grow" role="status" aria-hidden="true"/>) ||
      <span>Confirm</span>}</button>
      </div>
    </div>
  </div>
  <div className="card my-4 border-primary">
    <div className="card-header text-white bg-primary">
      <strong>Sample Files</strong>
    </div>
    <div className="card-body">
      <button onClick={() => {document.getElementById("processer").click();}} className={'btn btn-primary btn-lg d-block w-100 mt-3'}><a id="processer" href={processorSample} download={"Processor_samplefile.csv"} style={{color: "#ffffff", textAlign: "center"}}>Download Processor Sample File</a></button>
      <button onClick={() => {document.getElementById("result").click();}} className={'btn btn-primary btn-lg d-block w-100 mt-3'}><a id="result" href={resultSample} download={"Result_samplefile.xlsx"} style={{color: "#ffffff", textAlign: "center"}}>Download Result Sample File</a></button>
    </div>
    </div>
    </div>
}

const ConfirmPage = () => {
  const { id } = useParams()
  const { push } = useHistory();
  const location = useLocation();
  var data = (location.state);
  const [select, setSelection] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  var user = data.user;

  if (data === undefined) {
    window.location.assign("upload/" + id);
    return <>
    </> 
  } else {
    var newDataset = []
    
    function uploadData(newData, updateData, user) {
      setIsLoading(true)
      if (newData.length > 0 || updateData.length > 0) {
        var config = {
          method: "POST",
          url: process.env.REACT_APP_API_URL + '/uploadv2/dataupdater/',
          headers: {
            'Content-Type': 'application/json'
          },
          data: {newData: newData, updateData: updateData, user: user}
        };
    
        axios(config).then(res => {
          console.log(`Success`);
          push({ 
            pathname: '/' + id + '/completed',
            state: res.data
          });
        })
        .catch(err => {
          console.log(err);
        })
      } else {
        push({ 
          pathname: '/' + id + '/error',
          state: {
            error: "Error at update stage",
            message: "Unknown Error. Check Logs",
            link: ""
          }
        });
      }
    }

    const newRows = []
    for (var i = 0; i < data.New.length; i++) {
      newRows.push({
        id: data.New[i]["recordID"],
        date: data.New[i]["CheckIn Date"],
        sampleid: data.New[i]["Sample ID"],
        barcode: data.New[i]["barcode"],
        processor: data.New[i]["Processor"]==="" ? "-":data.New[i]["Processor"],
        ext: data.New[i]["Ext"]===""?"-":data.New[i]["Ext"],
        DnaWash: data.New[i]["DNA Wash Lot #"]===""?"-":data.New[i]["DNA Wash Lot #"],
        DnaWashDate: data.New[i]["DNA Wash Date Opened"]===""?"-":data.New[i]["DNA Wash Date Opened"],
        LysisLotNum: data.New[i]["Lysis/binding Lot #"]===""?"-":data.New[i]["Lysis/binding Lot #"],
        LysisLotDate: data.New[i]["Lysis/binding Date Opened"]===""?"-":data.New[i]["Lysis/binding Date Opened"],
        BeadLotNum: data.New[i]["Beads Lot #"]===""?"-":data.New[i]["Beads Lot #"],
        BeadLotDate: data.New[i]["Beads Date Made"]===""?"-":data.New[i]["Beads Date Made"],
        PKLotNum: data.New[i]["PK Lot #"]===""?"-":data.New[i]["PK Lot #"],
        PKLotDate: data.New[i]["PK Date Made"]===""?"-":data.New[i]["PK Date Made"],
        MasterMixIni: data.New[i]["MasterMix maker initials"]===""?"-":data.New[i]["MasterMix maker initials"],
        PCRTower: data.New[i]["PCR tower"]===""?"-":data.New[i]["PCR tower"],
        yval: data.New[i]["yvalue"]===""?"-":data.New[i]["yvalue"],
        total: data.New[i]["total"]===""?"-":data.New[i]["total"],
        gender: data.New[i]["gender"]===""?"-":data.New[i]["gender"]
      })
      newDataset.push(createData(data.New[i]["CheckIn Date"], data.New[i]["Sample ID"], data.New[i]["Processor"], data.New[i]["Ext"], data.New[i]["DNA Wash Lot #"], data.New[i]["DNA Wash Date Opened"], data.New[i]["Lysis/binding Lot #"], data.New[i]["Lysis/binding Date Opened"], data.New[i]["Beads Lot #"], data.New[i]["Beads Date Made"], data.New[i]["PK Lot #"], data.New[i]["PK Date Made"], data.New[i]["MasterMix maker initials"], data.New[i]["PCR tower"], data.New[i]["yvalue"], data.New[i]["total"], data.New[i]["gender"], data.New[i]["recordID"], data.New[i]["barcode"]));
    }

    function createData(date, id, pro, ext, DNAWashLot, DNAWashDateOpened, LysisbindingLot, LysisbindingDateOpened, BeadsLot, BeadsDateMade, PKLot, PKDateMade, MasterMixmakerinitials, PCRtower, yval, tot, gen, recordID, barcode) {
      return {date, id, pro, ext, DNAWashLot, DNAWashDateOpened, LysisbindingLot, LysisbindingDateOpened, BeadsLot, BeadsDateMade, PKLot, PKDateMade, MasterMixmakerinitials, PCRtower, yval, tot, gen, recordID, barcode};
    }

    const newColumns = [
      { field: 'date', headerName: 'CheckIn Date', sortable: false},
      { field: 'sampleid', headerName: 'Sample ID', sortable: false},
      { field: 'barcode', headerName: 'Barcode', sortable: false},
      { field: 'processor', headerName: 'Processor', sortable: false},
      { field: 'ext', headerName: 'Ext', sortable: false},
      { field: 'DnaWash', headerName: 'DNA Wash Lot #', sortable: false},
      { field: 'DnaWashDate', headerName: 'DNA Wash Date Opened', sortable: false},
      { field: 'LysisLotNum', headerName: 'Lysis/binding Lot #', sortable: false},
      { field: 'LysisLotDate', headerName: 'Lysis/binding Date Opened', sortable: false},
      { field: 'BeadLotNum', headerName: 'Beads Lot #', sortable: false},
      { field: 'BeadLotDate', headerName: 'Beads Date Made', sortable: false},
      { field: 'PKLotNum', headerName: 'PK Lot #', sortable: false},
      { field: 'PKLotDate', headerName: 'PK Date Made', sortable: false},
      { field: 'MasterMixIni', headerName: 'MasterMix maker initials', sortable: false},
      { field: 'PCRTower', headerName: 'PCR tower', sortable: false},
      { field: 'yval', headerName: 'y Vlaue', sortable: false},
      { field: 'total', headerName: 'Total Value', sortable: false},
      { field: 'gender', headerName: 'Gender', sortable: false}
    ]

    const exDiffColumns = [
      { field: 'date', headerName: 'CheckIn Date', sortable: false},
      { field: 'sampleid', headerName: 'Sample ID', sortable: false},
      { field: 'barcode', headerName: 'Barcode', sortable: false},
      { field: 'processoro', headerName: 'Processor(Old Value)', sortable: false},
      { field: 'processorn', headerName: 'Processor(New Value)', sortable: false},
      { field: 'exto', headerName: 'Ext(Old Value)', sortable: false},
      { field: 'extn', headerName: 'Ext(New Value)', sortable: false},
      { field: 'DnaWasho', headerName: 'DNA Wash Lot #(Old Value)', sortable: false},
      { field: 'DnaWashn', headerName: 'DNA Wash Lot #(New Value)', sortable: false},
      { field: 'DnaWashDateo', headerName: 'DNA Wash Date Opened(Old Value)', sortable: false},
      { field: 'DnaWashDaten', headerName: 'DNA Wash Date Opened(New Value)', sortable: false},
      { field: 'LysisLotNumo', headerName: 'Lysis/binding Lot #(Old Value)', sortable: false},
      { field: 'LysisLotNumn', headerName: 'Lysis/binding Lot #(New Value)', sortable: false},
      { field: 'LysisLotDateo', headerName: 'Lysis/binding Date Opened(Old Value)', sortable: false},
      { field: 'LysisLotDaten', headerName: 'Lysis/binding Date Opened(New Value)', sortable: false},
      { field: 'BeadLotNumo', headerName: 'Beads Lot #(Old Value)', sortable: false},
      { field: 'BeadLotNumn', headerName: 'Beads Lot #(New Value)', sortable: false},
      { field: 'BeadLotDateo', headerName: 'Beads Date Made(Old Value)', sortable: false},
      { field: 'BeadLotDaten', headerName: 'Beads Date Made(New Value)', sortable: false},
      { field: 'PKLotNumo', headerName: 'PK Lot #(Old Value)', sortable: false},
      { field: 'PKLotNumn', headerName: 'PK Lot #(New Value)', sortable: false},
      { field: 'PKLotDateo', headerName: 'PK Date Made(Old Value)', sortable: false},
      { field: 'PKLotDaten', headerName: 'PK Date Made(New Value)', sortable: false},
      { field: 'MasterMixInio', headerName: 'MasterMix maker initials(Old Value)', sortable: false},
      { field: 'MasterMixInin', headerName: 'MasterMix maker initials(New Value)', sortable: false},
      { field: 'PCRTowero', headerName: 'PCR tower(Old Value)', sortable: false},
      { field: 'PCRTowern', headerName: 'PCR tower(New Value)', sortable: false},
      { field: 'yvalo', headerName: 'y Vlaue(Old Value)', sortable: false},
      { field: 'yvaln', headerName: 'y Vlaue(New Value)', sortable: false},
      { field: 'totalo', headerName: 'Total Value(Old Value)', sortable: false},
      { field: 'totaln', headerName: 'Total Value(New Value)', sortable: false},
      { field: 'gendero', headerName: 'Gender(Old Value)', sortable: false},
      { field: 'gendern', headerName: 'Gender(New Value)', sortable: false},
    ]

    if (data.processorPresent === false) {
      newColumns[3].hide = true;
      newColumns[4].hide = true;
      newColumns[5].hide = true;
      newColumns[6].hide = true;
      newColumns[7].hide = true;
      newColumns[8].hide = true;
      newColumns[9].hide = true;
      newColumns[10].hide = true;
      newColumns[11].hide = true;
      newColumns[12].hide = true;
      newColumns[13].hide = true;
      newColumns[14].hide = true;

      exDiffColumns[3].hide = true;
      exDiffColumns[4].hide = true;
      exDiffColumns[5].hide = true;
      exDiffColumns[6].hide = true;
      exDiffColumns[7].hide = true;
      exDiffColumns[8].hide = true;
      exDiffColumns[9].hide = true;
      exDiffColumns[10].hide = true;
      exDiffColumns[11].hide = true;
      exDiffColumns[12].hide = true;
      exDiffColumns[13].hide = true;
      exDiffColumns[14].hide = true;
      exDiffColumns[15].hide = true;
      exDiffColumns[16].hide = true;
      exDiffColumns[17].hide = true;
      exDiffColumns[18].hide = true;
      exDiffColumns[19].hide = true;
      exDiffColumns[20].hide = true;
      exDiffColumns[21].hide = true;
      exDiffColumns[22].hide = true;
      exDiffColumns[23].hide = true;
      exDiffColumns[24].hide = true;
      exDiffColumns[25].hide = true;
      exDiffColumns[26].hide = true;
    }
    if (data.resultPresent === false) {
      newColumns[15].hide = true;
      newColumns[16].hide = true;
      newColumns[17].hide = true;

      exDiffColumns[27].hide = true;
      exDiffColumns[28].hide = true;
      exDiffColumns[29].hide = true;
      exDiffColumns[30].hide = true;
      exDiffColumns[31].hide = true;
      exDiffColumns[32].hide = true;
    }

    const exDiffRows = [];
    for (var j = 0; j < data.ExistsDiff.length; j++) {
      exDiffRows.push({
        id: data.ExistsDiff[j]["record ID"],
        date: data.ExistsDiff[j]["CheckIn Date"],
        sampleid: data.ExistsDiff[j]["Sample ID"],
        barcode: data.ExistsDiff[j]["barcode"],
        processoro: (data.ExistsDiff[j]["Original"]["Processor"]===undefined) ? null:data.ExistsDiff[j]["Original"]["Processor"],
        processorn: (data.ExistsDiff[j]["New"]["Processor"]===undefined) ? null:data.ExistsDiff[j]["New"]["Processor"],
        exto: (data.ExistsDiff[j]["Original"]["Ext"]===undefined) ? null:data.ExistsDiff[j]["Original"]["Ext"],
        extn: (data.ExistsDiff[j]["New"]["Ext"]===undefined) ? null:data.ExistsDiff[j]["New"]["Ext"],
        DnaWasho: (data.ExistsDiff[j]["Original"]["DNA Wash Lot #"]===undefined) ? null:data.ExistsDiff[j]["Original"]["DNA Wash Lot #"],
        DnaWashn: (data.ExistsDiff[j]["New"]["DNA Wash Lot #"]===undefined) ? null:data.ExistsDiff[j]["New"]["DNA Wash Lot #"],
        DnaWashDateo: (data.ExistsDiff[j]["Original"]["DNA Wash Date Opened"]===undefined) ? null:data.ExistsDiff[j]["Original"]["DNA Wash Date Opened"],
        DnaWashDaten: (data.ExistsDiff[j]["New"]["DNA Wash Date Opened"]===undefined) ? null:data.ExistsDiff[j]["New"]["DNA Wash Date Opened"],
        LysisLotNumo: (data.ExistsDiff[j]["Original"]["Lysis/binding Lot #"]===undefined) ? null:data.ExistsDiff[j]["Original"]["Lysis/binding Lot #"],
        LysisLotNumn: (data.ExistsDiff[j]["New"]["Lysis/binding Lot #"]===undefined) ? null:data.ExistsDiff[j]["New"]["Lysis/binding Lot #"],
        LysisLotDateo: (data.ExistsDiff[j]["Original"]["Lysis/binding Date Opened"]===undefined) ? null:data.ExistsDiff[j]["Original"]["Lysis/binding Date Opened"],
        LysisLotDaten: (data.ExistsDiff[j]["New"]["Lysis/binding Date Opened"]===undefined) ? null:data.ExistsDiff[j]["New"]["Lysis/binding Date Opened"],
        BeadLotNumo: (data.ExistsDiff[j]["Original"]["Beads Lot #"]===undefined) ? null:data.ExistsDiff[j]["Original"]["Beads Lot #"],
        BeadLotNumn: (data.ExistsDiff[j]["New"]["Beads Lot #"]===undefined) ? null:data.ExistsDiff[j]["New"]["Beads Lot #"],
        BeadLotDateo: (data.ExistsDiff[j]["Original"]["Beads Date Made"]===undefined) ? null:data.ExistsDiff[j]["Original"]["Beads Date Made"],
        BeadLotDaten: (data.ExistsDiff[j]["New"]["Beads Date Made"]===undefined) ? null:data.ExistsDiff[j]["New"]["Beads Date Made"],
        PKLotNumo: (data.ExistsDiff[j]["Original"]["PK Lot #"]===undefined) ? null:data.ExistsDiff[j]["Original"]["PK Lot #"],
        PKLotNumn: (data.ExistsDiff[j]["New"]["PK Lot #"]===undefined) ? null:data.ExistsDiff[j]["New"]["PK Lot #"],
        PKLotDateo: (data.ExistsDiff[j]["Original"]["PK Date Made"]===undefined) ? null:data.ExistsDiff[j]["Original"]["PK Date Made"],
        PKLotDaten: (data.ExistsDiff[j]["New"]["PK Date Made"]===undefined) ? null:data.ExistsDiff[j]["New"]["PK Date Made"],
        MasterMixInio: (data.ExistsDiff[j]["Original"]["MasterMix maker initials"]===undefined) ? null:data.ExistsDiff[j]["Original"]["MasterMix maker initials"],
        MasterMixInin: (data.ExistsDiff[j]["New"]["MasterMix maker initials"]===undefined) ? null:data.ExistsDiff[j]["New"]["MasterMix maker initials"],
        PCRTowero: (data.ExistsDiff[j]["Original"]["PCR tower"]===undefined) ? null:data.ExistsDiff[j]["Original"]["PCR tower"],
        PCRTowern: (data.ExistsDiff[j]["New"]["PCR tower"]===undefined) ? null:data.ExistsDiff[j]["New"]["PCR tower"],
        yvalo: (data.ExistsDiff[j]["Original"]["yvalue"]===undefined) ? null:data.ExistsDiff[j]["Original"]["yvalue"],
        yvaln: (data.ExistsDiff[j]["New"]["yvalue"]===undefined) ? null:data.ExistsDiff[j]["New"]["yvalue"],
        totalo: (data.ExistsDiff[j]["Original"]["total"]===undefined) ? null:data.ExistsDiff[j]["Original"]["total"],
        totaln: (data.ExistsDiff[j]["New"]["total"]===undefined) ? null:data.ExistsDiff[j]["New"]["total"],
        gendero: (data.ExistsDiff[j]["Original"]["gender"]===undefined) ? null:data.ExistsDiff[j]["Original"]["gender"],
        gendern: (data.ExistsDiff[j]["New"]["gender"]===undefined) ? null:data.ExistsDiff[j]["New"]["gender"]
      })
    }

    let exists = "";
    for (let i = 0; i < data.Exists.length; i++) {
      exists += data.Exists[i]["Sample ID"];
      if (i < data.Exists.length-1) {
        exists += ", "
      }
    }

    const handleSelectionChange = (x) => {
      const selectedIDs = new Set(x);
      setSelection(exDiffRows.filter((row) =>
        selectedIDs.has(row.id.toString())
      ))
    }

  return <div>
    <div id={"new-samples"}  hidden={data.New.length===0} >
        <Typography  style={{textAlign: "center"}} variant="h4" id="tableTitle" component="div">
        TO BE UPDATED
      </Typography>
      {newRows.length > 0 &&
        <DataGrid headerName={"Test"} hideFooter disableColumnMenu={true} autoHeight rows={newRows} columns={newColumns} pageSize={100} />
      }
    </div>
    <br></br>
    <div id={"diff-samples"} hidden={data.ExistsDiff.length===0} >
      <Typography  style={{textAlign: "center"}} variant="h4" id="tableTitle" component="div">
        ALREADY EXISTS BUT DATA IS DIFFERENT
      </Typography>
      {exDiffRows.length > 0 &&
        <DataGrid headerName={"Test"} columnBuffer={14} hideFooter disableColumnMenu={true} autoHeight rows={exDiffRows} columns={exDiffColumns} pageSize={100} checkboxSelection disableColumnSelector onSelectionModelChange={handleSelectionChange} pinnedColumns={["date", "sampleid", "barcode"]}/>
      }
    </div>
    <br></br>
    <div id={"unaffected-samples"} hidden={data.Exists.length===0} >
      <Typography  style={{textAlign: "center"}} variant="h4" id="tableTitle" component="div">
        ALREADY UPLOADED AND UNAFFECTED
      </Typography>
            <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{exists}</TableCell>
            </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
    </div>
    <br></br>
    <div hidden={data.New.length===0 && data.ExistsDiff.length===0}>
      <button className={'btn btn-primary btn-lg d-block w-100 mt-3'}
        disabled={(newDataset.length === 0 && select.length === 0) || isLoading} onClick={() => uploadData(newDataset, select, user)}>
        {(isLoading && <span className="spinner-grow" role="status" aria-hidden="true"/>) ||
        <span>Send Data</span>}</button>
    </div>
    <div hidden={data.New.length!==0 || data.ExistsDiff.length!==0}>
      <button title={'Click here to go back to uploads'} className={'btn btn-primary btn-lg d-block w-100 mt-3'}
        onClick={() => window.location.replace(window.location.origin +"/upload/" + id)}><span>No New Data To Update!</span></button>
    </div>
  </div>
  }
}

export default App;
